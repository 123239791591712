import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Container } from 'react-bootstrap';
import Layout from '../components/layout';
import Testimonial from '../components/Testimonial';
import Yoast from '../components/Yoast';

function TestimonialsTemplate({ pageContext }) {
  const wpPage = pageContext.wpPage;
  const data = useStaticQuery(graphql`
    {
      testimonials: allWordpressWpTestimonials {
        edges {
          node {
            id
            acf {
              company
              name
              rating
            }
            title
            content
          }
        }
      }
    }
  `);

  return (
    <Layout hasRecentWork={false} hasTestimonials={false}>
      <Yoast title={wpPage.yoast_title} meta={wpPage.yoast_meta} />
      <div className="template--testimonials">
        <Container>
          <h1 className="page-title">Testimonials</h1>
          {data.testimonials.edges.map((edge, index) => (
            <Testimonial
              title={edge.node.title}
              content={edge.node.content}
              key={index}
            />
          ))}
        </Container>
      </div>
    </Layout>
  );
}

TestimonialsTemplate.propTypes = {
  pageContext: PropTypes.object,
};

export default TestimonialsTemplate;
