import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const Yoast = ({ title, meta }) => {
  return <Helmet title={title} meta={meta}></Helmet>;
};

Yoast.propTypes = {
  title: PropTypes.string,
  meta: PropTypes.array,
};

export default Yoast;
